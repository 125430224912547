import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/UNtap3fADxU">
    <SEO title="Sloth - Seven Things" />
  </Layout>
)

export default SermonPost
